<template>
  <div class="filters-sidebar__bottom">
    <CustomButton
      theme="white"
      class="filters-sidebar__bottom__cta"
      specific-width="18rem"
      @click="handleApplyFilters"
      :disabled="disableResetFilters"
    >
      {{ $t('See all results') }}
    </CustomButton>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomButton } from '~/components/General/FormElements';
import { useCategoryPage, useUiState } from '~/composables';

export default defineComponent({
  name: 'FiltersSidebarBottom',
  components: {
    CustomButton,
  },
  props: {
    categoryId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { isFilterSidebarOpen, toggleFilterSidebar } = useUiState();
    const { loadProducts, disableResetFilters } = useCategoryPage(
      props.categoryId
    );

    const handleApplyFilters = async () => {
      if (isFilterSidebarOpen.value) toggleFilterSidebar(props.categoryId);
      await loadProducts();
    };

    return {
      handleApplyFilters,
      disableResetFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
.filters-sidebar__bottom {
  border-top: var(--form-element-border);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 6.375rem;
  @include flex-center;
  z-index: 3;
  @include to-tablet-max {
    position: relative;
    background-color: var(--c-white);
    margin-top: -0.0625rem;
  }
}
</style>
