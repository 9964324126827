<template>
  <div
    class="header__wishlist-cart-user__icon header__wishlist-cart-user__wishlist"
    @click="handleClick"
  >
    <nuxt-img
      class="header__wishlist-cart-user__icon header__wishlist-cart-user__wishlist__icon"
      src="/icons/wishlistHeartW.svg"
      alt="Wishlist Icon"
    />
    <div
      class="header__wishlist-cart-user__icon header__wishlist-cart-user__wishlist__counter"
      v-show="wishlistItemsCount && wishlistItemsCount > 0"
    >
      {{ wishlistItemsCount }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useWishlist } from '~/composables';

export default defineComponent({
  name: 'HeaderWishlist',
  components: {},
  props: {},
  setup() {
    const { wishlistItemsCount, toggleWishlistSidebar } = useWishlist();

    const handleClick = async () => {
      await toggleWishlistSidebar();
    };

    return {
      wishlistItemsCount,
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__wishlist-cart-user__wishlist {
  position: relative;
  //@include flex-center;
  &__icon {
    border-radius: unset;
  }
  &__counter {
    position: absolute;
    top: -0.5rem;
    right: -0.75rem;
    @include for-desktop {
      right: 1.125rem;
    }
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    @include border-radius-standard;
    background-color: var(--c-black);
    @include flex-center;
    @include text(
      var(--font-family),
      bold,
      0.8rem,
      0.041rem,
      1rem,
      var(--c-primary),
      uppercase
    );
  }
}
</style>
