<template>
  <div class="header__search">
    <div class="header__search__input" @keyup.enter="handleSearch">
      <CustomInput
        class="header__search__input__component"
        input-name="search"
        input-id="search"
        :input-placeholder="
          $device.isDesktop ? $t('What are you looking for?') : ''
        "
        :override-style="{ width: '100%' }"
        :has-icon="true"
        @input="(e) => (searchTerm = e)"
      />
      <CustomButton
        @click="handleSearch"
        class="header__search__input__button"
        :disabled="!searchTerm"
      >
        {{ $t('Search') }}
      </CustomButton>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { CustomInput, CustomButton } from '~/components/General/FormElements';

export default defineComponent({
  name: 'HeaderSearch',
  components: { CustomInput, CustomButton },
  setup() {
    const router = useRouter();
    const searchTerm = ref();
    const { localePath } = useContext();

    const handleSearch = async (event) => {
      await router.push({
        path: localePath('/search'),
        query: { q: searchTerm.value },
      });
      searchTerm.value = null;
    };

    return {
      handleSearch,
      searchTerm,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.625rem;
  //border-top: 1px solid #e5e5e5;
  &__input {
    position: relative;
    min-width: 15rem;
    @include for-mobile {
      width: 95%;
    }
    &__button {
      position: absolute;
      top: 0;
      right: 0;
      width: 30vw !important;
      max-width: 13.375rem;
      height: var(--form-element-mobile-height);
    }
    &__component {
      #search {
        font-family: var(--ri-font-family);
        font-size: 2.8vw;
        letter-spacing: 0.0894rem;
        line-height: 1.4375rem;
      }
    }
  }
}
@include from-tablet-min {
  .header__search {
    position: relative;
    padding-top: 0;
    &__input {
      width: 43.38%;
      height: auto;
      @include border-radius-standard;
      background-color: #f4f4f4;
      &__button {
        height: var(--form-element-desktop-height);
      }
    }
  }
}
</style>
