var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-popup"},[_c('SfModal',{staticClass:"modal",attrs:{"visible":!!_vm.loginAndRegisterModalOpen},on:{"close":function($event){return _vm.setModal('')}},scopedSlots:_vm._u([{key:"modal-bar",fn:function(){return [_c('SfBar',{staticClass:"sf-modal__bar smartphone-only",attrs:{"title":_vm.$t(_vm.barTitle)},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('SfButton',{staticClass:"sf-button--pure sf-bar__icon",attrs:{"aria-label":"close","type":"button"},on:{"click":function($event){return _vm.setModal('')}}},[_c('SfIcon',{attrs:{"icon":"cross","color":"white","size":"14px"}})],1)]},proxy:true}])})]},proxy:true}])},[_vm._v(" "),_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.loginAndRegisterModalOpen === 'login')?_c('div',[_c('ValidationObserver',{key:("log-in-" + _vm.loginAndRegisterModalOpen),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogin)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),(_vm.error.login)?_c('div',{staticClass:"error-message"},[_vm._v("\n              "+_vm._s(_vm.$t(_vm.error.login))+"\n            ")]):_vm._e(),_vm._v(" "),_c('CustomButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button btn-yellow sf-button",attrs:{"type":"submit","disabled":_vm.loading || invalid,"is-full-width":true}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Login')))])])],1),_vm._v(" "),_c('br')],1)]}}],null,false,1852030415)}),_vm._v(" "),_c('div',{staticClass:"action"},[_c('span',{staticClass:"sf-button-link",on:{"click":function($event){return _vm.setModal('forgotten')}}},[_vm._v("\n            "+_vm._s(_vm.$t('Forgotten password?'))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"no-account"},[_vm._v("\n            "+_vm._s(_vm.$t('No account'))+"\n          ")]),_vm._v(" "),_c('span',{staticClass:"sf-button-link",on:{"click":function($event){return _vm.setModal('register')}}},[_vm._v("\n            "+_vm._s(_vm.$t('Register here'))+"\n          ")])])],1):(_vm.loginAndRegisterModalOpen === 'forgotten')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Forgot Password')))]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"id":"forgotten-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleForgotten)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-modal-email'),expression:"'forgot-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Forgot Password Modal Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_vm._v(" "),(_vm.forgotPasswordError.request)?_c('div',{staticClass:"thank-you"},[_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'It was not possible to request a new password, please check the entered email address.'
                  ))+"\n              ")]),_vm._v(" "),_c('br')]):_vm._e(),_vm._v(" "),_c('CustomButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-modal-submit'),expression:"'forgot-modal-submit'"}],staticClass:"sf-button--full-width form__button btn-yellow sf-button",attrs:{"type":"submit","is-full-width":"","disabled":_vm.forgotPasswordLoading || !_vm.form.username}},[_c('SfLoader',{class:{ loader: _vm.forgotPasswordLoading },attrs:{"loading":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Reset Password')))])])],1),_vm._v(" "),_c('div',{staticClass:"action"},[_c('p',{staticClass:"no-account"},[_vm._v(_vm._s(_vm.$t('or')))]),_vm._v(" "),_c('div',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-login-to-your-account'),expression:"'login-modal-login-to-your-account'"}],staticClass:"sf-button-link",on:{"click":function($event){return _vm.setModal('login')}}},[_vm._v("\n                "+_vm._s(_vm.$t('login in to your account'))+"\n              ")])])],1)]}}])})],1):(_vm.isThankYouAfterForgotten)?_c('div',{staticClass:"thank-you"},[_c('i18n',{staticClass:"thank-you__paragraph",attrs:{"tag":"p","path":"forgotPasswordConfirmation"}},[_c('span',{staticClass:"thank-you__paragraph--bold"},[_vm._v(_vm._s(_vm.userEmail))])]),_vm._v(" "),_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t('Thank You Inbox'))+"\n        ")])],1):_c('div',[_c('Register')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }