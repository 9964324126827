<template>
  <div class="sf-product-card">
    <nuxt-link class="sf-button--pure sf-product-card__link" :to="link">
      <div
        class="sf-product-card__image-wrapper"
        @mouseover="isHovering = true"
        @mouseleave="isHovering = false"
      >
        <template v-if="thumbnailSource">
          <nuxt-img
            v-show="!isHovering"
            class="sf-product-card__image"
            :src="thumbnailSource"
            :alt="imageAlt"
          />
          <nuxt-img
            v-show="isHovering"
            class="sf-product-card__image"
            :src="rolloverSource"
            preload
            :alt="imageAlt"
          />
        </template>
        <nuxt-img
          v-else
          class="product-image-placeholder"
          src="logo.png"
          :alt="imageAlt"
          width="200"
          height="200"
        />
      </div>
    </nuxt-link>
    <slot name="title" v-bind="{ title, link }">
      <SfButton
        :link="link"
        class="sf-product-card__title"
        data-testid="product-link"
      >
        <span>
          {{ title }}
        </span>
      </SfButton>
    </slot>
    <slot
      name="price"
      v-bind="{
        specialPrice,
        regularPrice,
      }"
    >
      <div class="sf-product-card__price__container">
        <SfPrice
          :class="{ 'display-none': !regularPrice }"
          class="sf-product-card__price"
          :regular="regularPrice"
          :special="specialPrice"
        />
      </div>
    </slot>
    <slot name="colors" v-bind="{ colors }" v-if="colors && colors.length > 0">
      <SfColorPicker
        :class="{ 'display-none': colors.length === 0 }"
        class="sf-product-card__colors"
        label="Choose color"
        :is-open="true"
        @click:toggle="toggleColorPicker"
      >
        <SfColor
          v-for="(color, i) in colorsArray"
          :key="color.value"
          :color="color.color"
          :selected="color.selected"
          class="sf-product-card__color"
          :class="{ 'display-none': i > 3 && showBadge }"
          @click="handleSelectedColor(i)"
        />
        <SfBadge
          v-if="showBadge"
          class="sf-product-card__colors-badge color-secondary"
        >
          {{ `+${colors.length - 4}` }}
        </SfBadge>
      </SfColorPicker>
    </slot>
    <slot name="discount" v-bind="{ discountPercentage, specialPrice }">
      <div class="sf-product-card__labels">
        <div
          class="sf-product-card__labels__label discount"
          v-if="discountPercentage"
        >
          {{ `- ${discountPercentage} %` }}
        </div>
        <div class="sf-product-card__labels__label sale" v-if="specialPrice">
          {{ $t('Sale') }}
        </div>
      </div>
    </slot>
    <slot name="heart">
      <nuxt-img
        class="sf-button--pure sf-product-card__wishlist-icon"
        :src="
          itemsInWishlist && itemsInWishlist[product.sku]
            ? '/wishlist_full.png'
            : '/wishlist_empty.svg'
        "
        alt="Wishlist icon"
        @click="handleWishlistClick(product.sku)"
        :key="`wishlist-card-${product.sku}-${wishlistItemsCount}`"
      />
    </slot>
  </div>
</template>

<script>
import {
  SfPrice,
  SfButton,
  SfColor,
  SfColorPicker,
  SfBadge,
} from '@storefront-ui/vue';
import { computed, ref } from '@nuxtjs/composition-api';
import { useCart, useWishlist } from '~/composables';
import { isEmpty } from 'lodash-es';

export default {
  name: 'SfProductCardCustom',
  methods: { isEmpty },
  components: {
    SfPrice,
    SfButton,
    SfColor,
    SfColorPicker,
    SfBadge,
  },
  props: {
    product: {
      type: [Array, Object, String],
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    discountPercentage: {
      type: [Number, String],
      default: null,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    wishlistIcon: {
      type: String,
      required: false,
      default: 'heart',
    },
    isBigProductCard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isHovering = ref(false);
    const { addItem, error: cartError } = useCart();
    const { itemsInWishlist, toggleWishlistItem, wishlistItemsCount } =
      useWishlist();
    const openColorPicker = ref(true);
    const toggleColorPicker = () => {
      openColorPicker.value = !openColorPicker.value;
    };

    const colorsArray = computed(() =>
      props.colors.filter((color) => !!color.color)
    );

    const thumbnailSource = computed(() =>
      props.product?.thumbnail?.url
        ? `${props.product?.thumbnail?.url}?w=${
            props.isBigProductCard ? 600 : 278
          }&f=a`
        : ''
    );
    const rolloverSource = computed(() =>
      props.product?.rollover_image?.url
        ? `${props.product?.rollover_image?.url}?w=${
            props.isBigProductCard ? 600 : 278
          }&f=a`
        : thumbnailSource.value
    );

    const handleSelectedColor = (colorIndex) => {
      if (props.colors.length > 0) {
        props.colors.map((color, i) => () => {
          if (colorIndex === i) {
            emit('click:colors', color);
          }
          return false;
        });
      }
    };

    const handleWishlistClick = async (sku) => {
      await toggleWishlistItem(sku);
    };

    const showBadge = computed(() => props.colors.length > 5);

    return {
      props,
      addItem,
      cartError,
      itemsInWishlist,
      showBadge,
      isHovering,
      rolloverSource,
      thumbnailSource,
      colorsArray,
      toggleColorPicker,
      handleSelectedColor,
      handleWishlistClick,
      wishlistItemsCount,
    };
  },
};
</script>

<style lang="scss">
.sf-product-card::after {
  display: var(--hidden);
}
.sf-product-card__image-wrapper {
  display: flex;
  flex-direction: column;
  background: var(--c-white);
  @include from-tablet-max-to-tablet-1500 {
    padding: 0;
  }
  @include from-desktop-1500 {
    padding: 0;
  }
  .sf-image--wrapper {
    height: 100%;
  }
  .sf-product-card__link {
    text-align: center;
    flex-grow: 1;
  }
}
.short_description {
  margin-bottom: 0.5rem;
}
.sf-product-card {
  display: var(--flex);
  flex-direction: column;
  @include background-product-card;
  &__price {
    &__container {
      display: flex;
      align-items: center;
      margin-top: 0.3125rem;
      padding: 0 1.25rem;
    }
  }
  &__labels {
    &__label {
      &.sale {
        text-transform: uppercase;
      }
    }
  }
  &:hover {
    --product-card-z-index: unset;
  }
  &__image:nth-child(even) {
    position: unset;
  }
  &__image:nth-child(odd) {
    position: unset;
  }
  &__link {
    margin-top: 0;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
    img {
      height: auto !important;
      width: auto !important;
      max-width: var(--w-full);
      /*max-height: 15rem;*/
      margin: auto;
    }
    .product-image-placeholder {
      filter: grayscale(100%);
      opacity: 0.2;
      margin: auto;
      @include ri-from-mobile {
        min-height: var(--card-image-min-height);
      }
    }
  }
  &__wishlist-icon {
    position: absolute;
    opacity: 1;
    padding: 0.7581rem 0.6837rem;
    top: 0.3125rem;
    right: 0.3125rem;
    @include pointer;
    height: 3.125rem;
    //width: 3.125rem;
    @include to-mobile-max {
      width: 0.875rem;
    }
  }
}
</style>
