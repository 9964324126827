<template>
  <SfSidebar
    class="wishlist-sidebar"
    :visible="isWishlistSidebarOpen"
    title="I tuoi preferiti"
    @close="toggleWishlistSidebar"
    position="right"
  >
    <template #content-top>
      <div class="wishlist-sidebar__top">
        <div class="wishlist-sidebar__top__title">
          {{ $t('Your wishlist') }}
        </div>
        <div class="wishlist-sidebar__top__counter" v-if="wishlistItemsCount">
          {{ wishlistItemsCount }}
        </div>
        <Cross
          class="wishlist-sidebar__top__close"
          @click="toggleWishlistSidebar"
        />
      </div>
    </template>
    <LoadingDots v-if="wishlistLoading" />
    <div
      v-if="
        !wishlistLoading &&
        wishlist &&
        wishlist.items &&
        wishlist.items.items.length > 0
      "
      class="wishlist-sidebar__items"
    >
      <WishlistSidebarItem
        v-for="(wishlistItem, wishlistItemIndex) in wishlist.items.items"
        :key="`wishlist-sidebar-${wishlist.uid}-item-${wishlistItemIndex}-${wishlistItem.uid}`"
        :product="wishlistItem.product"
        :wishlist-item-uid="wishlistItem.uid"
        :wishlist-item-grn="wishlistItem.item_grn"
      />
    </div>
  </SfSidebar>
</template>

<script>
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import { useUiState, useWishlist } from '~/composables';
import { LoadingDots } from '~/components/General/FormElements';
import { Cross } from '~/components/General/Icons';
import { WishlistSidebarItem } from './WishlistSidebarInternal';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfSidebar,
    WishlistSidebarItem,
    LoadingDots,
    Cross,
  },
  setup() {
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { wishlist, wishlistLoading, loadWishlist, wishlistItemsCount } =
      useWishlist();

    onMounted(async () => {
      if (!wishlist.value && !wishlistLoading.value) {
        await loadWishlist();
      }
    });

    return {
      isWishlistSidebarOpen,
      toggleWishlistSidebar,
      wishlist,
      wishlistLoading,
      wishlistItemsCount,
    };
  },
});
</script>

<style lang="scss">
.wishlist-sidebar {
  --sidebar-z-index: 4;
  --overlay-z-index: 4;
  .wishlist-sidebar__selector {
    margin-top: 1rem;
  }
  .sf-sidebar {
    &__content {
      padding-top: 0;
    }
    &__aside {
      .sf-bar.smartphone-only {
        display: none;
      }
      @include background-general;
    }
    &__circle-icon {
      display: none !important;
    }
    &__top {
      padding: 2.6875rem 3.1875rem 0 3.1875rem;
    }
    &__title {
      display: none;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    position: relative;
    gap: 1rem;
    &__title {
      @include text(
        var(--font-family),
        bold,
        1.9rem,
        0,
        2.9rem,
        var(--c-primary)
      );
    }
    &__counter {
      @include text(
        var(--font-family),
        bold,
        0.8rem,
        0.04rem,
        1.7rem,
        var(--c-black)
      );
      @include border-radius-standard;
      background-color: var(--c-white);
      width: 1.7rem;
      height: 1.7rem;
      @include flex-center;
    }
    &__close {
      position: absolute;
      right: 0;
      top: 0;
      @include pointer;
      path {
        fill: var(--c-white);
      }
    }
  }
  &__empty {
    @include text-20x26-regular;
    @include flex-column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
}
@include from-tablet-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__top {
        .sf-heading {
          &__title.h3 {
            font-size: 1.5vw;
          }
        }
      }
    }
    &__top-right {
      position: absolute;
      right: 2rem;
      top: 1.6rem;
      padding: 0;
      &__new {
        &__label {
          font-size: 1.1vw;
        }
      }
    }
  }
}
@include from-desktop-min {
  .wishlist-sidebar {
    .sf-sidebar {
      &__aside {
        width: 30vw;
      }
    }
    &__top-right {
      top: 2rem;
    }
  }
}
</style>
