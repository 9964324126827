<template>
  <span class="header__top-bar text-14-regular-white" v-show="!isScrolledPast">
    {{ $t(message) }}
  </span>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';

export default defineComponent({
  name: 'HeaderTopBar',
  props: {
    message: {
      type: String,
      default: 'Shipping is free for orders of at least €69.00',
    },
  },
  setup() {
    const isScrolledPast = ref(false);

    const checkScroll = () => {
      try {
        const topBarElement = document.querySelector('.header__top-bar');
        isScrolledPast.value = window.scrollY > topBarElement.offsetHeight;
      } catch (error) {
        console.warn(error);
      }
    };

    const debouncedCheckScroll = debounce(checkScroll, 200);

    onMounted(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', debouncedCheckScroll);
      }
    });

    onUnmounted(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', debouncedCheckScroll);
      }
      debouncedCheckScroll.cancel(); // Cancel any pending execution
    });

    return {
      isScrolledPast,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  @include background-general;
  color: var(--c-white);
  text-transform: uppercase;
  text-align: center;
  padding: 0.25rem 0.9375rem;
  font-size: 0.65rem;
  line-height: 1rem;
  @include for-desktop {
    font-size: 0.8125rem;
    line-height: 25px;
    letter-spacing: 0.0081rem;
    padding: 0.5rem 0;
  }
}
</style>
