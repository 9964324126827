<template>
  <div class="header__wishlist-cart-user">
    <nuxt-img
      class="header__wishlist-cart-user__icon header__wishlist-cart-user__user"
      src="/icons/userIconW.svg"
      @click="handleUserIconClick"
      alt="User Icon"
    />
    <HeaderWishlist />
    <SfButton
      @click="toggleCartSidebar"
      class="header__wishlist-cart-user__icon sf-button--pure"
    >
      <nuxt-img
        class="header__wishlist-cart-user__icon"
        src="/icons/bagW.svg"
        alt="Cart Icon"
      />
      <SfBadge class="cart-items" v-if="cartTotalItems">
        {{ cartTotalItems }}
      </SfBadge>
    </SfButton>
  </div>
</template>

<script>
import {
  defineComponent,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import { useUiState, useUser, useCart } from '~/composables';
import { SfButton, SfBadge } from '@storefront-ui/vue';
import HeaderWishlist from './HeaderWishlist.vue';

export default defineComponent({
  name: 'HeaderWishlistCartUser',
  components: { SfButton, SfBadge, HeaderWishlist },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleLoginAndRegisterModal } = useUiState();
    const { isAuthenticated } = useUser();
    const { cartTotalItems } = useCart();

    const handleUserIconClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'my-account' }));
      } else {
        toggleLoginAndRegisterModal();
      }
    };
    return {
      handleUserIconClick,
      toggleCartSidebar,
      cartTotalItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__wishlist-cart-user {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  &__icon {
    border-radius: unset;
    .cart-items.sf-badge {
      --badge-background: none;
      position: absolute;
      top: -0.625rem;
      right: -0.625rem;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0;
      @include border-radius-standard;
      @include text(
        var(--font-family),
        bold,
        0.8rem,
        0.04rem,
        1.25rem,
        var(--c-primary),
        uppercase
      );
    }
  }
}
@include from-tablet-min {
  .header__wishlist-cart-user {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    //border-bottom: 1px solid #d5d5d5;
    padding: 0 2.6vw;
    position: relative;
    /*    &::before {
      content: '';
      position: absolute;
      top: 25%;
      left: 0;
      height: 2.75rem;
      width: 1px;
      background-color: #d5d5d5;
    }*/
    &__icon {
      padding-left: 16.4%;
      padding-right: 0;
      &:hover {
        cursor: pointer;
      }
      &.sf-button {
        justify-content: flex-start;
      }
      .cart-items.sf-badge {
        top: -0.4375rem;
        right: 0.5rem;
      }
    }
  }
}
</style>
